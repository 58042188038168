import React from "react";
import "../App.css";
import profilePic from "../assets/images/profile.jpg";

function Home() {
    return (
        <div className="homeContainer">
            <h1>Greetings, Philip Hilm here!</h1>
            <div className="textContent">
                <p>
                    My path from high-level poker, through developing a
                    proprietary neural network library to find Nash
                    Equilibriums, leading deep into AI research, and now
                    innovating in the DeFi sector has equipped me with profound
                    expertise in C++, Python, and Solidity. You can delve deeper
                    into my professional story and explore the projects I've
                    been part of on{" "}
                    <a
                        href="https://www.linkedin.com/in/philip-hilm/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        my LinkedIn profile
                    </a>
                    .
                </p>
                <h2>Collaborative Innovation</h2>
                <p>
                    Are you ready to challenge the boundaries of game theory,
                    AI, or DeFi? I excel at transforming complex concepts into
                    tangible solutions. Let's partner to innovate and shape the
                    future. For collaboration opportunities or to exchange
                    ideas, reach out to{" "}
                    <a href="mailto:me@zilliun.org">me@zilliun.org</a>.
                </p>
            </div>
            <img src={profilePic} alt="Philip Hilm" className="profilePic" />
        </div>
    );
}

export default Home;
