import React, { useState, useEffect } from "react";
import { ethers } from "ethers";

const metricsContractABI = [
    // Include only the relevant function signatures
    "function liquidityPool() external view returns (address)",
    "function getUserTokenBalances(address user) external view returns (uint256, uint256)",
    "function getUserLiquidityProduct(address user) external view returns (uint256)",
    "function getUserTotalValue(address user, address valuationToken, uint8 desiredDecimals) external view returns (uint256)",
];

const liquidityPoolABI = [
    // ABI to interact with liquidity pool for fetching thisToken and thatToken
    "function thisToken() external view returns (address)",
    "function thatToken() external view returns (address)",
];

const erc20ABI = [
    // ABI for fetching token symbol
    "function symbol() external view returns (string)",
];

const metricsContractAddress = "0xD6535D01Efe5F150298563813dd2cc58c29207be";
const defaultValuationTokenAddress =
    "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174";

function Metrics() {
    const [provider, setProvider] = useState(null);
    const [account, setAccount] = useState("");
    const [userAddress, setUserAddress] = useState("");
    const [valuationToken, setValuationToken] = useState(
        defaultValuationTokenAddress
    );
    const [desiredDecimals, setDesiredDecimals] = useState(4);
    const [tokenBalances, setTokenBalances] = useState({
        thisTokenValue: "0",
        thatTokenValue: "0",
    });
    const [thisTokenSymbol, setThisTokenSymbol] = useState("");
    const [thatTokenSymbol, setThatTokenSymbol] = useState("");
    const [liquidityProduct, setLiquidityProduct] = useState("0");
    const [totalValue, setTotalValue] = useState("0");
    const [valuationTokenSymbol, setValuationTokenSymbol] = useState("");
    const [liquidityPoolAddress, setLiquidityPoolAddress] = useState("");

    useEffect(() => {
        if (typeof window.ethereum !== "undefined") {
            const provider = new ethers.BrowserProvider(window.ethereum);
            setProvider(provider);
            checkMetamaskInstallation(provider);
        } else {
            alert(
                "MetaMask is not installed. Please install MetaMask to use this feature."
            );
        }
    }, []);

    const handleDesiredDecimalsChange = (e) => {
        let validDesiredDecimals = parseInt(e.target.value, 10);
        validDesiredDecimals = isNaN(validDesiredDecimals)
            ? 0
            : Math.min(Math.max(validDesiredDecimals, 0), 18);

        // Update the state with the new value (which may have been adjusted)
        setDesiredDecimals(validDesiredDecimals);
    };

    // This useEffect is for initializing the provider and setting up the MetaMask account change listener.
    useEffect(() => {
        const handleAccountsChanged = (accounts) => {
            if (accounts[0]) {
                setAccount(accounts[0]);
                setUserAddress(accounts[0]); // Update the userAddress state with the new account

                // Directly use the most recent provider for fetching contract data
                fetchContractData(accounts[0], provider);
            }
        };

        if (typeof window.ethereum !== "undefined") {
            const provider = new ethers.BrowserProvider(window.ethereum);
            setProvider(provider);

            // Prompt the user to connect their account
            provider
                .send("eth_requestAccounts", [])
                .then(handleAccountsChanged);

            window.ethereum.on("accountsChanged", handleAccountsChanged);
        } else {
            alert(
                "MetaMask is not installed. Please install MetaMask to use this feature."
            );
        }

        return () => {
            // Cleanup the event listener when the component unmounts
            if (window.ethereum) {
                window.ethereum.removeListener(
                    "accountsChanged",
                    handleAccountsChanged
                );
            }
        };
    }, []); // This effect runs only once on mount

    const initiateWalletConnection = async (provider) => {
        try {
            const accounts = await provider.send("eth_requestAccounts", []);
            const defaultAccount = accounts[0];
            setAccount(defaultAccount);
            setUserAddress(defaultAccount); // Set the userAddress to the connected account by default
        } catch (error) {
            console.error("An error occurred connecting to MetaMask:", error);
        }
    };

    const checkMetamaskInstallation = async (provider) => {
        try {
            const accounts = await provider.send("eth_requestAccounts", []);
            const defaultAccount = accounts[0];
            setAccount(defaultAccount);
            setUserAddress(defaultAccount); // Set the userAddress to the connected account by default
        } catch (error) {
            console.error("An error occurred connecting to MetaMask:", error);
        }
    };

    const fetchContractData = async () => {
        if (!provider) return;

        try {
            const metricsContract = new ethers.Contract(
                metricsContractAddress,
                metricsContractABI,
                provider
            );
            const liquidityPoolAddress = await metricsContract.liquidityPool();
            setLiquidityPoolAddress(liquidityPoolAddress); // Save the liquidity pool address
            const liquidityPoolContract = new ethers.Contract(
                liquidityPoolAddress,
                liquidityPoolABI,
                provider
            );
            const thisTokenAddress = await liquidityPoolContract.thisToken();
            const thatTokenAddress = await liquidityPoolContract.thatToken();

            const thisTokenContract = new ethers.Contract(
                thisTokenAddress,
                erc20ABI,
                provider
            );
            const thatTokenContract = new ethers.Contract(
                thatTokenAddress,
                erc20ABI,
                provider
            );
            const valuationTokenContract = new ethers.Contract(
                valuationToken,
                erc20ABI,
                provider
            );

            const [thisTokenValue, thatTokenValue] =
                await metricsContract.getUserTokenBalances(userAddress);
            const liquidityProductValue =
                await metricsContract.getUserLiquidityProduct(userAddress);
            const totalValueInValuationToken =
                await metricsContract.getUserTotalValue(
                    userAddress,
                    valuationToken,
                    desiredDecimals
                );

            setTokenBalances({
                thisTokenValue: ethers.formatUnits(thisTokenValue, 18),
                thatTokenValue: ethers.formatUnits(thatTokenValue, 18),
            });
            setLiquidityProduct(ethers.formatUnits(liquidityProductValue, 18));
            setTotalValue(
                ethers.formatUnits(totalValueInValuationToken, desiredDecimals)
            );

            const thisSymbol = await thisTokenContract.symbol();
            const thatSymbol = await thatTokenContract.symbol();
            const valuationSymbol = await valuationTokenContract.symbol();

            setThisTokenSymbol(thisSymbol);
            setThatTokenSymbol(thatSymbol);
            setValuationTokenSymbol(valuationSymbol);
        } catch (error) {
            console.error("An error occurred fetching contract data:", error);
            // Checking for specific error codes if necessary, for instance:
            if (error.code === "BAD_DATA") {
                alert(
                    "The contract data isn't available at the moment. Please try again later."
                );
            } else {
                alert("An unexpected error occurred. Please try again.");
            }
        }
    };

    // Effect to fetch contract data whenever the userAddress changes
    useEffect(() => {
        if (!userAddress) return;

        // Define a local provider instance if needed within this effect
        const provider = new ethers.BrowserProvider(window.ethereum);
        fetchContractData(userAddress, provider);
    }, [userAddress]);

    return (
        <div>
            <h1>Metrics</h1>
            {account ? (
                <>
                    {/* Input Fields */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "72%",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <label>User Address:</label>
                            <input
                                type="text"
                                value={userAddress}
                                onChange={(e) => setUserAddress(e.target.value)}
                                style={{
                                    flexGrow: 1,
                                    padding: "5px",
                                    fontFamily: "monospace",
                                    textAlign: "right",
                                    maxWidth: "310px",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <label>Valuation Token Address:</label>
                            <input
                                type="text"
                                value={valuationToken}
                                onChange={(e) =>
                                    setValuationToken(e.target.value)
                                }
                                style={{
                                    flexGrow: 1,
                                    padding: "5px",
                                    fontFamily: "monospace",
                                    textAlign: "right",
                                    maxWidth: "310px",
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <label>Desired Valuation Decimals (0-18):</label>
                            <input
                                type="text"
                                value={desiredDecimals}
                                onChange={handleDesiredDecimalsChange}
                                style={{
                                    flexGrow: 1,
                                    padding: "5px",
                                    fontFamily: "monospace",
                                    textAlign: "right",
                                    maxWidth: "310px",
                                }}
                            />
                        </div>
                    </div>

                    {/* Update Button */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "72%",
                            marginBottom: "20px",
                        }}
                    >
                        <button
                            onClick={fetchContractData}
                            style={{ width: "auto", padding: "5px 10px" }}
                        >
                            Update Pool Metrics
                        </button>
                    </div>

                    {/* Output Values */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "72%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <span style={{ textAlign: "left" }}>
                                This Balance ({thisTokenSymbol}):
                            </span>
                            <span
                                style={{
                                    textAlign: "right",
                                    fontFamily: "monospace",
                                }}
                            >
                                {parseFloat(
                                    tokenBalances.thisTokenValue
                                ).toFixed(desiredDecimals)}
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <span style={{ textAlign: "left" }}>
                                That Balance ({thatTokenSymbol}):
                            </span>
                            <span
                                style={{
                                    textAlign: "right",
                                    fontFamily: "monospace",
                                }}
                            >
                                {parseFloat(
                                    tokenBalances.thatTokenValue
                                ).toFixed(desiredDecimals)}
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <span style={{ textAlign: "left" }}>
                                Liquidity Product (&radic;{thisTokenSymbol}{" "}
                                &times; {thatTokenSymbol}):
                            </span>
                            <span
                                style={{
                                    textAlign: "right",
                                    fontFamily: "monospace",
                                }}
                            >
                                {parseFloat(liquidityProduct).toFixed(
                                    desiredDecimals
                                )}
                            </span>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "10px",
                            }}
                        >
                            <span style={{ textAlign: "left" }}>
                                Total Value ({valuationTokenSymbol}):
                            </span>
                            <span
                                style={{
                                    textAlign: "right",
                                    fontFamily: "monospace",
                                }}
                            >
                                {parseFloat(totalValue).toFixed(
                                    desiredDecimals
                                )}
                            </span>
                        </div>
                    </div>
                </>
            ) : (
                <button onClick={() => initiateWalletConnection()}>
                    Connect MetaMask Wallet
                </button>
            )}
        </div>
    );
}

export default Metrics;
