import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Metrics from "./components/Metrics"; // Ensure you have a Metrics component
import "./App.css";

function App() {
    return (
        <Router>
            <div className="main-content">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/metrics" element={<Metrics />} />{" "}
                    {/* Metrics route */}
                </Routes>
            </div>
        </Router>
    );
}

export default App;
